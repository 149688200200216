<template>
  <div class="section">
    <div class="container ist-container">
      <template v-if="task == null">
        <h1 class="title"><Loader v-bind:isLoading="true" />Results</h1>
      </template>
      <template v-else>
        <div class="buttons is-pulled-right">
          <EditJobName v-bind:name="job.name" v-on:jobNameEdit="handleJobNameEdit" />
          <ReportModal
            v-if="job.status == 'completed'"
            :is-loading="isPdfLoading"
            @generateReport="downloadPdf"
          />
        </div>
        <h1 class="title no-space-bottom" data-cy="job-title">
          {{ job.name }}
        </h1>
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <p>started: {{ task.started_at | formatDate }}</p>
            </div>
            <div class="level-item">
              <p>completed: {{ task.finished_at | formatDate }}</p>
            </div>
            <div class="level-item">
              <span
                class="tag is-medium"
                v-bind:class="{ 'is-warning': task.status !== 'success' }"
                data-cy="task-status"
                >{{ task.status }}</span
              >
            </div>
          </div>
        </div>

        <InputsSummary v-bind:input="task.input" />

        <template v-if="job.status == 'completed'">
          <h2 v-if="task.input.workflow == 'herg-fitting'" class="title is-4">
            hERG Fitting
          </h2>
          <h2
            v-else-if="task.input.workflow == 'hill-fitting'"
            class="title is-4"
          >
            Hill Fitting
          </h2>
          <div v-else class="tabs is-boxed tabsWithSpace">
            <ul>
              <li v-bind:class="{ 'is-active': activeSection === 0 }">
                <a v-on:click="activeSection = 0">hERG Fitting</a>
              </li>
              <li v-bind:class="{ 'is-active': activeSection === 1 }">
                <a v-on:click="activeSection = 1">Hill Fitting</a>
              </li>
              <li v-bind:class="{ 'is-active': activeSection === 2 }">
                <a v-on:click="activeSection = 2" data-cy="workflow-ap-button"
                  >AP Simulation</a
                >
              </li>
            </ul>
          </div>

          <!-- hERG fitting -->
          <div
            v-if="
              task.input.workflow == 'herg-fitting' ||
              (task.input.workflow == 'ap-simulation' && activeSection == 0)
            "
          >
            <p class="with-bottom-space">
              Drug-hERG Kinetics Optimal Parameters
            </p>
            <div class="table-container">
              <table class="ist-table-striped" data-cy="herg-pars-table">
                <thead>
                  <tr>
                    <th class="left">
                      Parameter
                      <sup>
                        <font-awesome-icon
                          title="Kmax is the maximum drug effect at saturating concentrations (unitless)
Ku is the drug unbinding rate (1/ms)
n is the Hill coefficient (unitless)
halfmax is the nth power of the half maximal effective concentration of the drug, EC50n (nM)
Vhalftrap is the membrane voltage at which half of the drug-bound channels are open (mV)"
                          icon="info-circle"
                          class="help-icon"
                        />
                      </sup>
                    </th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, i) in hERGPars" v-bind:key="i">
                    <td
                      class="left"
                      v-html="parameterSubscript(row.param)"
                    ></td>
                    <td>{{ row.value }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p class="with-bottom-space">
              Drug-hERG Kinetics Parameters Uncertainty
            </p>
            <div class="table-container">
              <table class="ist-table-striped" data-cy="herg-ci-table">
                <thead>
                  <tr>
                    <th class="left">
                      Parameter
                      <sup>
                        <font-awesome-icon
                          title="Log10(Kmax) is the decimal logarithm of the maximum drug effect at saturating concentrations. Kmax is unitless
Log10(Ku) is the decimal logarithm of the drug unbinding rate. Ku is in 1/ms
n is the Hill coefficient. n is unitless
Log10(halfmax) or Log10(EC50n) is the decimal logarithm of the nth power of the half maximal effective concentration of the drug, EC50n (nM)
Vhalftrap is the membrane voltage at which half of the drug-bound channels are open (mV)
Log10(slope) or Log10(Kmax/EC50n) is the linear approximation of the Emax sigmoidal dose-response equation
    Emax ≈ (Kmax/EC50n) * Dn  where D is the drug concentration
"
                          icon="info-circle"
                          class="help-icon"
                        />
                      </sup>
                    </th>
                    <th>Value</th>
                    <th>95% CI</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, i) in hERGCI95" v-bind:key="i">
                    <td
                      class="left"
                      v-html="parameterSubscript(row.param)"
                    ></td>
                    <td>{{ row.value | nbFormat }}</td>
                    <td>
                      [{{ row.lower | nbFormat }}, {{ row.upper | nbFormat }}]
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p class="space-top">Parameter Bootstrap Distributions</p>
            <div class="columns is-centered">
              <div class="column is-8">
                <figure class="image">
                  <img
                    v-bind:src="media('herg-fitting/figs/histograms.png')"
                    data-cy="herg-distribution-plot"
                  />
                </figure>
              </div>
            </div>
            <p class="space-top">Milnes Sensitivity Analysis</p>
            <figure class="image">
              <img
                v-bind:src="media('herg-fitting/figs/drug_sensRange.png')"
                data-cy="herg-sensitivity-plot"
              />
            </figure>
            <p class="buttons space-top">
              <a
                class="button is-primary"
                v-bind:href="media('herg-fitting/results/drug/boot_pars.csv')"
                target="_blank"
                download
                data-cy="herg-boot-download"
              >
                <span class="icon">
                  <font-awesome-icon icon="file-download" />
                </span>
                <span>Bootstrap Samples</span>
              </a>
              <a
                class="button is-primary"
                v-bind:href="media('herg-fitting/results/drug/pars.txt')"
                target="_blank"
                download
                data-cy="herg-pars-download"
              >
                <span class="icon">
                  <font-awesome-icon icon="file-download" />
                </span>
                <span>Optimal Parameters</span>
              </a>
            </p>
          </div>

          <!-- Hill fitting -->
          <div
            v-if="
              task.input.workflow == 'hill-fitting' ||
              (task.input.workflow == 'ap-simulation' && activeSection == 1)
            "
          >
            <div class="table-container">
              <table class="ist-table-striped" data-cy="hill-ci-table">
                <thead>
                  <tr>
                    <th class="left">Channel</th>
                    <th>
                      <span>IC</span>
                      <sub>50</sub> (nM)
                    </th>
                    <th>95% CI</th>
                    <th>n</th>
                    <th>95% CI</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, i) in HillTable" v-bind:key="i">
                    <template v-if="row.IC50.optimal == 'NA'">
                      <td class="left">
                        <span v-html="parameterSubscript(row.channel)"></span>
                      </td>
                      <td colspan="4">
                        <span class="has-text-grey-dark"
                          ><font-awesome-icon
                            class="channel-warning-icon"
                            icon="exclamation-triangle"
                          />
                          &nbsp;Hill fitting failed</span
                        >
                      </td>
                    </template>
                    <template v-else>
                      <td
                        class="left"
                        v-html="parameterSubscript(row.channel)"
                      ></td>
                      <td>{{ row.IC50.optimal | nbFormat }}</td>
                      <td>
                        [{{ row.IC50.lower | nbFormat }},
                        {{ row.IC50.upper | nbFormat }}]
                      </td>
                      <td>{{ row.H.optimal | nbFormat }}</td>
                      <td>
                        [{{ row.H.lower | nbFormat }},
                        {{ row.H.upper | nbFormat }}]
                      </td>
                    </template>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="tabs is-centered is-toggle tabsWithSpace">
              <ul data-cy="hill-channel-list">
                <template v-for="ch in channelsWithValidData">
                  <li
                    v-if="task.input.hillChannels.includes(ch)"
                    v-bind:key="ch"
                    v-bind:class="{ 'is-active': plotChannel === ch }"
                  >
                    <a
                      v-html="parameterSubscript(ch)"
                      v-on:click="plotChannel = ch"
                    ></a>
                  </li>
                </template>
              </ul>
            </div>
            <div class="figure-container">
              <figure class="image">
                <img
                  v-bind:src="
                    media(
                      'hill-fitting/figs/drug_' +
                        plotChannel +
                        '_nls_mcmc_4.png'
                    )
                  "
                  data-cy="hill-histograms-plot"
                />
              </figure>
              <figure class="image">
                <img
                  v-bind:src="
                    media(
                      'hill-fitting/figs/drug_' +
                        plotChannel +
                        '_nls_mcmc_1.png'
                    )
                  "
                  data-cy="hill-channel-plot"
                />
              </figure>
            </div>
            <p class="buttons space-top">
              <a
                class="button is-primary"
                v-on:click="
                  saveAs(
                    'hill-fitting/results/drug/IC50_samples.csv',
                    'IC50_uncertainty.csv'
                  )
                "
                target="_blank"
                download
                data-cy="hill-samples-download"
              >
                <span class="icon">
                  <font-awesome-icon icon="file-download" />
                </span>
                <span>IC50 Samples</span>
              </a>
              <a
                class="button is-primary"
                v-on:click="
                  saveAs(
                    'hill-fitting/results/drug/IC50_optimal.csv',
                    'IC50_fixed.csv'
                  )
                "
                target="_blank"
                download
                data-cy="hill-optimal-download"
              >
                <span class="icon">
                  <font-awesome-icon icon="file-download" />
                </span>
                <span>IC50 Optimal</span>
              </a>
            </p>
          </div>

          <!-- AP simulation -->
          <div v-if="activeSection == 2">
            <div class="table-container">
              <table class="ist-table-striped" data-cy="ap-ci-table">
                <thead>
                  <tr>
                    <th class="left">
                      Dose
                      <sup>
                        <font-awesome-icon
                          title="Multipliers of Cmax"
                          icon="info-circle"
                          class="help-icon"
                        />
                      </sup>
                    </th>
                    <th># Samples</th>
                    <th>qNet (μC/μF)</th>
                    <th>95% CI</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, i) in APCI95" v-bind:key="i">
                    <td class="left">{{ row.dose }}</td>
                    <td>{{ row.samples | nbFormat }}</td>
                    <td>{{ row.value | nbFormat }}</td>
                    <td v-if="row.drug == 'control'">-</td>
                    <td v-else>
                      [{{ row.lower | nbFormat }}, {{ row.upper | nbFormat }}]
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <figure class="image qnet-plot">
              <img
                v-bind:src="media('ap-simulation/figs/qNet_CI95.png')"
                data-cy="ap-qnet-plot"
              />
            </figure>
            <p class="space-top">
              Manual Dataset Mapping (cf.
              <a
                :href="`${publicPath}Chang_2017.pdf`"
                target="_blank"
                rel="noopener noreferrer"
                >Chang et al. 2017</a
              >)
            </p>
            <figure class="image">
              <img
                v-bind:src="media('ap-simulation/figs/cipa_comparison_m.png')"
                data-cy="ap-cipa-m-plot"
              />
            </figure>
            <p class="space-top">Hybrid Dataset Mapping</p>
            <figure class="image">
              <img
                v-bind:src="media('ap-simulation/figs/cipa_comparison_h.png')"
                data-cy="ap-cipa-h-plot"
              />
            </figure>
            <p class="buttons space-top">
              <a
                class="button is-primary with-bottom-space"
                v-on:click="
                  saveAs(
                    'ap-simulation/results/fixed/metrics.csv',
                    'AP_fixed.csv'
                  )
                "
                target="_blank"
                download
                data-cy="ap-fixed-download"
              >
                <span class="icon">
                  <font-awesome-icon icon="file-download" />
                </span>
                <span>Fixed Metrics</span>
              </a>
              <a
                class="button is-primary with-bottom-space"
                v-on:click="
                  saveAs(
                    'ap-simulation/results/uncertainty/metrics.csv',
                    'AP_uncertainty.csv'
                  )
                "
                target="_blank"
                download
                data-cy="ap-uncertainty-download"
              >
                <span class="icon">
                  <font-awesome-icon icon="file-download" />
                </span>
                <span>Uncertainty Metrics</span>
              </a>
            </p>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import gql from 'graphql-tag';
import config from '@/config/apollo-config';
import Papa from 'papaparse';
import EditJobName from '@/components/Results/EditJobName.vue';
import ReportModal from '@/components/ReportModal.vue';
import Loader from '@/components/Loader.vue';
import InputsSummary from '@/components/Results/InputsSummary.vue';
import apiCall from '../lib/remote-calls';

Papa.parsePromise = (file) => new Promise((complete, error) => {
  Papa.parse(file, { complete, error, skipEmptyLines: true });
});

const labelsParameters = {
  Kmax: 'K<sub>max</sub>',
  Ku: 'K<sub>u</sub>',
  Vhalf: 'V<sub>halftrap</sub>',
  log10Kmax: 'log<sub>10</sub>(K<sub>max</sub>)',
  log10Ku: 'log<sub>10</sub>(K<sub>u</sub>)',
  log10halfmax: 'log<sub>10</sub>(halfmax)',
  log10slope: 'log<sub>10</sub>(slope)',
  hERG: 'I<sub>hERG</sub>',
  ICaL: 'I<sub>CaL</sub>',
  IK1: 'I<sub>K1</sub>',
  IKs: 'I<sub>Ks</sub>',
  INa: 'I<sub>Na</sub>',
  INaL: 'I<sub>NaL</sub>',
  Ito: 'I<sub>to</sub>',
};

export default {
  components: {
    EditJobName,
    InputsSummary,
    Loader,
    ReportModal,
  },
  computed: {
    HillTable() {
      const HillTable = [];
      const optimal = this.HillOptimal[0];
      if (optimal === undefined) return HillTable;
      const lower = this.HillCI95[0];
      const upper = this.HillCI95[1];
      this.channels.forEach((channel) => {
        const IC50Key = `${channel}_IC50`;
        const hKey = `${channel}_h`;
        if (IC50Key in optimal) {
          HillTable.push({
            channel,
            IC50: { optimal: optimal[IC50Key], lower: lower[IC50Key], upper: upper[IC50Key] },
            H: { optimal: optimal[hKey], lower: lower[hKey], upper: upper[hKey] },
          });
        }
      });
      return HillTable;
    },
    channelsWithValidData() {
      const fitSucceeded = [];
      this.HillTable.forEach((row) => {
        if (row.IC50.optimal !== 'NA') {
          fitSucceeded.push(row.channel);
        }
      });
      return fitSucceeded;
    },
  },
  data() {
    return {
      /* Job data */
      job: null,
      task: null,
      mediaBaseUrl: null,
      mediaContainerSAS: null,
      hERGCI95File: null,
      hERGParsTxt: null,
      HillOptimalFile: null,
      HillCI95File: null,
      APCI95File: null,
      hERGCI95: {},
      hERGPars: [],
      HillOptimal: {},
      HillCI95: {},
      APCI95: {},
      // for plots in Hill fitting
      plotChannel: 'hERG',
      channels: ['hERG', 'ICaL', 'IK1', 'IKs', 'INa', 'INaL', 'Ito'],
      // for layout
      activeSection: 0,
      // for PDF download
      isPdfLoading: false,
      publicPath: process.env.BASE_URL,
    };
  },
  filters: {
    paramSubscript(param) {
      return `<${param}>`;
    },
  },
  methods: {
    handleJobNameEdit(newJobName) {
      this.job.name = newJobName;
    },
    saveAs(filepath, filename) {
      const blobUrl = this.media(filepath);
      axios.get(blobUrl, {
        headers: { 'Content-Type': 'application/octet-stream' },
        responseType: 'blob',
      }).then((response) => {
        const a = document.createElement('a');
        const urlObj = window.URL.createObjectURL(response.data);
        a.href = urlObj;
        a.download = filename;
        a.click();
      }).catch((err) => {
        this.$utils.alertError(`Error downloading ${filename}: ${err}.`);
      });
    },
    parameterSubscript(param) {
      if (param in labelsParameters) {
        return labelsParameters[param];
      }
      return `${param}`;
    },
    media(filePath) {
      return (this.mediaBaseUrl != null && this.mediaContainerSAS != null && filePath != null) ? `${this.mediaBaseUrl}/${filePath}?${this.mediaContainerSAS}` : null;
    },
    // TODO to refactor with right method in API
    async fetchMedia() {
      const response = await this.$apollo.query({
        query: gql`
          query getMediaFile($product_name: ProductName!, $job_id: ID!, $file_name: String!) {
            url: getMediaFile(product_name: $product_name, job_id: $job_id, file_name: $file_name)
          }
        `,
        variables: {
          product_name: config.productName,
          job_id: this.$route.params.id,
          file_name: `task-${this.task.id}/input.json`,
        },
      });
      const fileUrl = response.data.url;
      const position = fileUrl.indexOf('input.json');
      this.mediaBaseUrl = fileUrl.substring(0, position - 1);
      this.mediaContainerSAS = fileUrl.substring(position + 'input.json'.length + 1, fileUrl.length);
    },
    async fetch() {
      try {
        // --- JOB
        const response = await this.$apollo.query({
          query: gql`
            query getJob($product_name: ProductName!, $job_id: ID!) {
              job: getJob(product_name: $product_name, job_id: $job_id) {
                _id
                name
                status
                created_at
                tasks {
                  id: _id
                  input
                  status
                  started_at
                  finished_at
                }
              }
            }
          `,
          variables: {
            product_name: config.productName,
            job_id: this.$route.params.id,
          },
        });
        this.job = response.data.job;
        [this.task] = this.job.tasks;

        if (this.task.status === 'success') {
          // --- MEDIA
          await this.fetchMedia();

          const { workflow } = this.task.input;
          if (workflow === 'herg-fitting' || workflow === 'ap-simulation') {
            this.hERGCI95File = (await axios.get(this.media('herg-fitting/results/drug/boot_CI95.csv'))).data;
            this.hERGParsTxt = (await axios.get(this.media('herg-fitting/results/drug/pars.txt'))).data;
          }
          if (workflow === 'hill-fitting' || workflow === 'ap-simulation') {
            this.HillOptimalFile = (await axios.get(this.media('hill-fitting/results/drug/IC50_optimal.csv'))).data;
            this.HillCI95File = (await axios.get(this.media('hill-fitting/results/drug/IC50_CI.csv'))).data;
          }
          if (workflow === 'ap-simulation') {
            this.APCI95File = (await axios.get(this.media('ap-simulation/results/qNet_CI95_with_control.csv'))).data;
          }
        }
      } catch (error) {
        console.error('error', error);
      }
    },
    async downloadPdf(userContent) {
      this.isPdfLoading = true;
      // user content
      const userContentTokens = [];
      Object.keys(userContent).forEach((key) => {
        userContentTokens.push(
          { key, type: 'string', data: userContent[key] },
        );
      });

      const { workflow } = this.task.input;

      const tokens = [
        ...userContentTokens,
        { key: 'title', type: 'string', data: this.job.name },
        { key: 'date', type: 'string', data: this.$options.filters.formatDate(this.job.created_at) },
      ];
      // Input Data
      const Cmax = workflow === 'ap-simulation' ? `Therapeutic C~max~: ${this.task.input.cmax} nM` : '';
      tokens.push({ key: 'Cmax', type: 'string', data: Cmax });
      const Doses = workflow === 'ap-simulation' ? `Doses: ${this.task.input.doses.split(',').join(', ')} x C~max~` : '';
      tokens.push({ key: 'Doses', type: 'string', data: Doses });
      if (workflow === 'herg-fitting' || workflow === 'ap-simulation') {
        const MilnesTable = {
          header: ['Conc (nM)', '# Exp.', '# Sweeps', 'Time (ms)', 'Frac. Current'],
          rows: this.task.input.hergInfo.map((row) => [
            this.$options.filters.nbFormat(row.conc),
            row.experiments,
            row.sweeps,
            `${row.minTime} - ${row.maxTime}`,
            `${this.$options.filters.nbFormat(row.minFrac)} - ${this.$options.filters.nbFormat(row.maxFrac)}`,
          ]),
          caption: 'Milnes Protocol Input Data',
        };
        tokens.push({ key: 'MilnesTable', type: 'table', data: MilnesTable });
      }
      if (workflow === 'hill-fitting' || workflow === 'ap-simulation') {
        const BlockTable1 = {
          header: ['Conc (nM)', 'I~hERG~', '#', 'I~CaL~', '#', 'I~NaL~', '#', 'I~Na~', '#'],
          rows: this.task.input.hillInfo.map((row) => [
            this.$options.filters.nbFormat(row.conc),
            this.formatBlockRange(row.hERG),
            row.hERG[2] > 0 ? row.hERG[2] : '',
            this.formatBlockRange(row.ICaL),
            row.ICaL[2] > 0 ? row.ICaL[2] : '',
            this.formatBlockRange(row.INaL),
            row.INaL[2] > 0 ? row.INaL[2] : '',
            this.formatBlockRange(row.INa),
            row.INa[2] > 0 ? row.INa[2] : '',
          ]),
          caption: 'Block Percentage Input Data for I~hERG~, I~CaL~, I~NaL~, and I~Na~ Channels',
        };
        const BlockTable2 = {
          header: ['Conc (nM)', 'I~Ks~', '#', 'I~to~', '#', 'I~K1~', '#'],
          rows: this.task.input.hillInfo.map((row) => [
            this.$options.filters.nbFormat(row.conc),
            this.formatBlockRange(row.INa),
            row.INa[2] > 0 ? row.INa[2] : '',
            this.formatBlockRange(row.IKs),
            row.IKs[2] > 0 ? row.IKs[2] : '',
            this.formatBlockRange(row.Ito),
            row.Ito[2] > 0 ? row.Ito[2] : '',
            this.formatBlockRange(row.IK1),
            row.IK1[2] > 0 ? row.IK1[2] : '',
          ]),
          caption: 'Block Percentage Input Data for I~Ks~, I~to~, and I~K1~ Channels',
        };
        tokens.push({ key: 'BlockTable1', type: 'table', data: BlockTable1 });
        tokens.push({ key: 'BlockTable2', type: 'table', data: BlockTable2 });
      }
      // hERG Fitting
      if (workflow === 'herg-fitting' || workflow === 'ap-simulation') {
        tokens.push({ key: 'hergTitle', type: 'string', data: '## hERG Fitting' });
        tokens.push({
          key: 'optimalTable',
          type: 'table',
          data: {
            header: ['Parameter', 'Value'],
            rows: this.hERGPars.map((row) => [
              this.parameterSubscript(row.param),
              row.value,
            ]),
            caption: 'Drug-hERG Kinetics Optimal Parameters',
          },
        });
        tokens.push({
          key: 'optimalLegend',
          type: 'string',
          data: `Legend for \\Cref{optimalTable}:
          
- K~max~ is the maximum drug effect at saturating concentrations (unitless)
- K~u~ is the drug unbinding rate (1/ms)
- n is the Hill coefficient (unitless)
- halfmax is the nth power of the half maximal effective concentration of the drug, EC$^n_{50}$ (nM)
- V~halftrap~ is the membrane voltage at which half of the drug-bound channels are open (mV)`,
        });
        tokens.push({
          key: 'uncertaintyTable',
          type: 'table',
          data: {
            header: ['Parameter', 'Value', '95% CI'],
            rows: this.hERGCI95.map((row) => [
              this.parameterSubscript(row.param),
              this.$options.filters.nbFormat(row.value),
              `[${this.$options.filters.nbFormat(row.lower)}, ${this.$options.filters.nbFormat(row.upper)}]`,
            ]),
            caption: 'Drug-hERG Kinetics Parameters Uncertainty',
          },
        });
        tokens.push({
          key: 'uncertaintyLegend',
          type: 'string',
          data: `Legend for \\Cref{uncertaintyTable}:

- Log10(K~max~) is the decimal logarithm of the maximum drug effect at saturating concentrations (K~max~ is unitless)
- Log10(K~u~) is the decimal logarithm of the drug unbinding rate  (K~u~ in 1/ms)
- n is the Hill coefficient (unitless)
- Log10(halfmax) or Log10(EC$_{50}^n$) is the decimal logarithm of the n-th power of the half maximal effective concentration of the drug (EC$_{50}^n$ in nM)
- V~halftrap~ is the membrane voltage at which half of the drug-bound channels are open (mV)
- Log10(slope) or Log10(K~max~/EC$_{50}^n$) is the linear approximation of the Emax sigmoidal dose-response equation  
  E~max~ = (Kmax/EC$^n_{50}$) * Dn  where D is the drug concentration`,
        });
        tokens.push({
          key: 'bootstrapDistribution',
          type: 'figure',
          data: {
            caption: 'Parameter Bootstrap Distribution',
            url: this.media('herg-fitting/figs/histograms.png'),
          },
        });
        tokens.push({
          key: 'sensitivityAnalysis',
          type: 'figure',
          data: {
            caption: 'Milnes Sensitivity Analysis',
            url: this.media('herg-fitting/figs/drug_sensRange.png'),
          },
        });
      }
      // Hill Fitting
      if (workflow === 'hill-fitting' || workflow === 'ap-simulation') {
        const warningFitFailed = [];
        const HillTableRows = [];
        this.HillTable.forEach((row) => {
          let HillTableRow = null;
          if (row.IC50.optimal !== 'NA') {
            HillTableRow = [this.parameterSubscript(row.channel)].concat([
              this.$options.filters.nbFormat(row.IC50.optimal),
              `[${this.$options.filters.nbFormat(row.IC50.lower)}, ${this.$options.filters.nbFormat(row.IC50.upper)}]`,
              this.$options.filters.nbFormat(row.H.optimal),
              `[${this.$options.filters.nbFormat(row.H.lower)}, ${this.$options.filters.nbFormat(row.H.upper)}]`,
            ]);
          } else {
            warningFitFailed.push(this.parameterSubscript(row.channel));
            HillTableRow = [this.parameterSubscript(row.channel), '', '', '', ''];
          }
          HillTableRows.push(HillTableRow);
        });
        tokens.push({ key: 'HillTitle', type: 'string', data: '## Hill Fitting' });
        tokens.push({
          key: 'HillTable',
          type: 'table',
          data: {
            header: ['Channel', 'IC~50~ (nM)', '95% CI', 'n', '95% CI'],
            rows: HillTableRows,
            caption: 'Hill Fitting and 95% Credible Intervals',
          },
        });
        if (warningFitFailed.length > 0) {
          tokens.push({
            key: 'fitFailedWarning',
            type: 'string',
            data: `Hill fitting failed for ${warningFitFailed.join(', ')} channel${warningFitFailed.length > 1 ? 's' : ''}.`,
          });
        }
        tokens.push({
          key: 'HillPlots',
          type: 'pairwiseFigures',
          data: this.channelsWithValidData.map((ch) => ({
            caption: `Hill Fitting for ${this.parameterSubscript(ch)} Channel`,
            url: [
              this.media(`hill-fitting/figs/drug_${ch}_nls_mcmc_4.png`),
              this.media(`hill-fitting/figs/drug_${ch}_nls_mcmc_1.png`),
            ],
          })),
        });
      }
      // AP Simulation
      if (workflow === 'ap-simulation') {
        tokens.push({ key: 'apTitle', type: 'string', data: '## AP Simulation' });
        tokens.push({
          key: 'apTable',
          type: 'table',
          data: {
            header: ['Dose', '# Samples', 'qNet (µC/µF)', '95% CI'],
            rows: this.APCI95.map((row) => [
              row.dose,
              this.$options.filters.nbFormat(row.samples),
              this.$options.filters.nbFormat(row.value),
              row.drug === 'control' ? '-' : `[${this.$options.filters.nbFormat(row.lower)}, ${this.$options.filters.nbFormat(row.upper)}]`,
            ]),
          },
        });
        tokens.push({ key: 'apLegend', type: 'string', data: '- Dose is the multiplier of C~max~' });
        tokens.push({
          key: 'qNet',
          type: 'figure',
          data: {
            url: this.media('ap-simulation/figs/qNet_CI95.png'),
            caption: 'qNet with 95% credible interval',
          },
        });
        tokens.push({
          key: 'manualDataset',
          type: 'figure',
          data: {
            url: this.media('ap-simulation/figs/cipa_comparison_m.png'),
            caption: 'Manual Dataset Mapping (cf. Chang et al. 2017 [1])',
          },
        });
        tokens.push({
          key: 'hybridDataset',
          type: 'figure',
          data: {
            url: this.media('ap-simulation/figs/cipa_comparison_h.png'),
            caption: 'Hybrid Dataset Mapping',
          },
        });
        tokens.push({
          key: 'References',
          type: 'string',
          data: `# References
          
[1] Uncertainty Quantification Reveals the Importance of Data Variability and Experimental Design Considerations for in Silico Proarrhythmia Risk Assessment

Chang, K. et al. Front. Physiol. 8:917 **2017** DOI: 10.3389/fphys.2017.00917`,
        });
      }
      const response = await apiCall('POST', 'api/pdf-export-func', {
        header: {
          Accept: 'application/json',
        },
        responseType: 'blob',
        data: {
          template: 'cipa',
          tokens,
        },
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute('download', 'CiPA_Report.pdf');
      document.body.appendChild(link);
      link.click();
      link.remove();
      this.isPdfLoading = false;
    },
    formatBlockRange(blockRange) {
      if (blockRange[0] === blockRange[1]) return this.$options.filters.nbFormat(blockRange[0]);
      if (blockRange[0] === Infinity || blockRange[0] === null) return '';
      const left = blockRange[0] === 0 ? '0' : this.$options.filters.nbFormat(blockRange[0]);
      const right = this.$options.filters.nbFormat(blockRange[1]);
      return `${left} - ${right}`;
    },
  },
  async mounted() {
    await this.fetch();

    const { workflow } = this.task.input;
    if (workflow === 'herg-fitting' || workflow === 'ap-simulation') {
      this.hERGCI95 = Papa.parse(this.hERGCI95File, { header: true, skipEmptyLines: true }).data;
      // parse pars.txt file
      const hERGPars = [];
      this.hERGParsTxt.split(/\r?\n/).forEach((line) => {
        if (line !== '') {
          const parts = line.split(' ');
          hERGPars.push({ param: parts[0], value: parts[1] });
        }
      });
      this.hERGPars = hERGPars;
    }
    if (workflow === 'hill-fitting' || workflow === 'ap-simulation') {
      this.HillOptimal = Papa.parse(this.HillOptimalFile, { header: true, skipEmptyLines: true }).data;
      this.HillCI95 = Papa.parse(this.HillCI95File, { header: true, skipEmptyLines: true }).data;
      [this.plotChannel] = this.channelsWithValidData;
    }
    if (workflow === 'ap-simulation') {
      const APCI95 = Papa.parse(this.APCI95File, { header: true, skipEmptyLines: true }).data;
      this.APCI95 = APCI95;
    }
  },
};
</script>

<style scoped>
.no-space-bottom {
  margin-bottom: 0.1em !important;
}
.with-bottom-space {
  margin-bottom: 1em;
}
figure.qnet-plot {
  width: 50%;
  margin: auto;
}
.space-top {
  margin-top: 3em;
  margin-bottom: 1em;
}
.figure-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.tabsWithSpace {
  margin-top: 2em;
  margin-bottom: 2em;
}
.channel-warning-icon {
  color: darkorange;
}
.icon-with-margin {
  margin-right: 0.5em;
}
.with-space-bottom {
  margin-bottom: 0.5em;
}
</style>
