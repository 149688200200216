<template>
  <div class="field with-space">
    <label class="label">
      {{ label }}
      <font-awesome-icon v-if="help" v-bind:title="help" icon="info-circle" class="help-icon" />
    </label>
    <p v-if="text" class="with-space">{{ text }}</p>
    <div class="control">
      <div v-for="(opt, index) in options" :key="index">
        <label class="radio my-extra-spacing">
          <input
            v-bind:id="id"
            type="radio"
            class="my-extra-spacing"
            v-bind:name="name"
            v-bind:value="index"
            v-bind:checked="value==index"
            v-on:change="$emit('input', $event.target.value)"
          />
          {{opt}}
        </label>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    label: String,
    help: String,
    text: String,
    value: Number,
    name: String,
    options: Array, // [{value:'xxx', label: 'xxx'}, ...]
  },
};
</script>

<style scoped>
label.my-extra-spacing {
  margin-bottom: 0.5em;
}
input.my-extra-spacing {
  margin-right: 0.5em;
}
</style>
