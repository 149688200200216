<template>
  <div class="section">
    <div class="container ist-container">
      <h1 class="title">New Simulation</h1>
      <StepIndicator
        v-model="activeStep"
        v-bind:labels="simTypeLabels[simType]"
        v-bind:warnings="stepWarnings"
      />
      <!-- step 0: setup -->
      <div v-if="activeStep === 0">
        <h2 class="title is-4">Setup</h2>
        <div class="columns">
          <div class="column is-6">
            <Field
              label="Compound name"
              id="s0-title"
              v-model="drug"
              v-bind:validate="highestVisitedStep > 0"
            />
            <FieldRadio
              label="Select simulation type"
              name="simType"
              v-model.number="simType"
              v-bind:options="simTypeOptions"
            />
          </div>
        </div>
        <StepButtons v-model="activeStep" />
      </div>
      <!-- step: herg fitting (step 1 on simTypes 0 and 2) -->
      <div v-if="activeStep === 1 && (simType === 0 || simType === 2)">
        <h2 class="title is-4">hERG Fitting</h2>
        <p class="with-space readable has-text-justified">
          hERG fitting performs uncertainty characterization for drug binding
          kinetics of the human Ether-à-go-go-Related Gene (hERG) channel gating
          model.
        </p>
        <div class="columns">
          <div class="column is-6">
            <Upload
              v-bind:filename="hergFilename"
              v-bind:status="hergUploadStatus"
              v-bind:headers="hergCsvHeaders"
              v-on:upload="handleHergFile"
              v-on:uploadAccept="handleHergUploadAccept"
              v-on:uploadReject="handleHergUploadReject"
              refName="hergFile"
              exampleFile="exempline.csv"
              help="The fitting model is calibrated for input data obtained with the Milnes protocol (Milnes et al. 2010). This tool may also accept data generated with a different voltage protocol.

The columns of the CSV file are:
• time: time in msec during the sweep
• frac: fractional current
• conc: drug concentration in nM
• exp: experiment (cell) number
• sweep: sweep number"
            />
          </div>
        </div>
        <StepButtons v-model="activeStep" />
      </div>
      <!-- step: hill fitting (step 1 on simType 1 and step 2 on simType 2) -->
      <div
        v-if="
          (activeStep === 1 && simType === 1) ||
          (activeStep === 2 && simType === 2)
        "
      >
        <h2 class="title is-4">Hill Fitting</h2>
        <p class="with-space readable has-text-justified">
          Hill fitting performs uncertainty characterization of dose-response
          curves for up to seven ionic currents
          <span>(I</span>
          <sub>hERG</sub>,
          <span>I</span>
          <sub>CaL</sub>,
          <span>I</span>
          <sub>NaL</sub>,
          <span>I</span>
          <sub>Na</sub>,
          <span>I</span>
          <sub>to</sub>,
          <span>I</span>
          <sub>Ks</sub>, and
          <span>I</span>
          <sub>K1</sub>
          <span>).</span>
        </p>
        <div class="columns">
          <div class="column is-6">
            <Upload
              v-bind:filename="hillFilename"
              v-bind:status="hillUploadStatus"
              v-bind:headers="hillCsvHeaders"
              v-on:upload="handleHillFile"
              v-on:uploadAccept="handleHillUploadAccept"
              v-on:uploadReject="handleHillUploadReject"
              refName="hillFile"
              exampleFile="exempline_block.csv"
              help="• The fitting algorithm requires block data measured at a minimum of four concentration values for each ion current. In case block data for as many as four concentrations would not be available for a given ion current, the algorithm will accept '0' block data values as input to be inserted at the user discretion at given concentration values - usually being the lowest ones.
• The fitting algorithm disregards block data values outside the range 0 -- 100.

The columns of the CSV file are:
• drug: the name of the drug
• conc: drug concentration in nM
• channel: ion channel type (hERG, ICaL, INaL, INa, IKs, Ito, IK1)
• block: percentage ion channel block"
            />
          </div>
        </div>
        <StepButtons v-model="activeStep" />
      </div>
      <!-- step: AP simulation (step 3 on simType 2) -->
      <div v-if="activeStep === 3 && simType === 2">
        <h2 class="title is-4">Action Potential Simulation</h2>
        <p class="with-space readable has-text-justified">
          <span class="is-italic">AP simulation</span> combines the results of
          the modules hERG fitting and Hill fitting to simulate the effects of a
          compound on the Action Potential (AP) of a population of ventricular
          cardiomyocyte cells and to estimate the population value of the safety
          marker qNet and its uncertainty interval.
        </p>
        <div class="columns">
          <div class="column is-6">
            <Field
              label="Therapeutic C<sub>max</sub> (nM)"
              id="s3-cmax"
              v-model="cmax"
              vpositive
              v-bind:validate="highestVisitedStep > 3"
            />
            <Concentrations
              v-model="strDoses"
              v-bind:concentrations="doses"
              v-on:concentrationsUpdate="handleDosesUpdate"
              v-bind:validate="highestVisitedStep > 3"
            />
          </div>
        </div>
        <StepButtons v-model="activeStep" />
      </div>
      <!-- step: run (step 2 on simTypes 0 and 1, step 4 on simType 2 -->
      <div
        v-if="
          (activeStep === 2 && simType < 2) ||
          (activeStep === 4 && simType === 2)
        "
      >
        <h2 class="title is-4">Summary</h2>
        <SummaryItem label="Compound name" v-bind:item="drug" />
        <div v-if="simType === 0 || simType === 2">
          <p v-bind:class="{ 'has-text-warning': hergUploadStatus !== 'ok' }">
            <span class="has-text-weight-bold">File name:</span>
            {{ hergFilename }}
          </p>
          <div class="table-container" v-if="hergInfo.length > 0">
            <table class="ist-table with-margin" data-cy="inputs-herg-table">
              <thead>
                <tr>
                  <th>Conc (nM)</th>
                  <th># Experiments</th>
                  <th>
                    # Sweeps
                    <sup>
                      <font-awesome-icon
                        title="Number of sweeps per experiment."
                        icon="info-circle"
                        class="help-icon"
                      />
                    </sup>
                  </th>
                  <th>Time (ms)</th>
                  <th>Frac Current</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in hergInfo" v-bind:key="index">
                  <td>{{ row.conc | nbFormat }}</td>
                  <td>{{ row.experiments }}</td>
                  <td>{{ row.sweeps }}</td>
                  <td>{{ row.minTime }} &ndash; {{ row.maxTime }}</td>
                  <td>
                    {{ row.minFrac | nbFormat }} &ndash;
                    {{ row.maxFrac | nbFormat }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="hill-div" v-if="simType === 1 || simType === 2">
          <p v-bind:class="{ 'has-text-warning': hillUploadStatus !== 'ok' }">
            <span class="has-text-weight-bold">File name:</span>
            {{ hillFilename }}
          </p>
          <div class="table-container" v-if="hillInfo.length > 0">
            <table class="ist-table with-margin" data-cy="inputs-hill-table">
              <colgroup>
                <col class="border-right" />
                <col span="7" />
              </colgroup>
              <thead>
                <tr class="double-tr">
                  <th colspan="1"></th>
                  <th colspan="14" class="has-text-centered">
                    Channel Block %
                  </th>
                </tr>
                <tr>
                  <th>Conc (nM)</th>
                  <th>
                    <span>I</span>
                    <sub>hERG</sub>
                  </th>
                  <th style="padding-left: 0px">#</th>
                  <th>
                    <span>I</span>
                    <sub>CaL</sub>
                  </th>
                  <th style="padding-left: 0px">#</th>
                  <th>
                    <span>I</span>
                    <sub>NaL</sub>
                  </th>
                  <th style="padding-left: 0px">#</th>
                  <th>
                    <span>I</span>
                    <sub>Na</sub>
                  </th>
                  <th style="padding-left: 0px">#</th>
                  <th>
                    <span>I</span>
                    <sub>Ks</sub>
                  </th>
                  <th style="padding-left: 0px">#</th>
                  <th>
                    <span>I</span>
                    <sub>to</sub>
                  </th>
                  <th style="padding-left: 0px">#</th>
                  <th>
                    <span>I</span>
                    <sub>K1</sub>
                  </th>
                  <th style="padding-left: 0px">#</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in hillInfo" v-bind:key="index">
                  <td>{{ row.conc }}</td>
                  <td v-html="formatBlockRange(row.hERG)"></td>
                  <td style="padding-left: 0px" class="has-text-grey">
                    <span v-if="row.hERG[2] > 0">{{ row.hERG[2] }}</span>
                  </td>
                  <td v-html="formatBlockRange(row.ICaL)"></td>
                  <td style="padding-left: 0px" class="has-text-grey">
                    <span v-if="row.ICaL[2] > 0">{{ row.ICaL[2] }}</span>
                  </td>
                  <td v-html="formatBlockRange(row.INaL)"></td>
                  <td style="padding-left: 0px" class="has-text-grey">
                    <span v-if="row.INaL[2] > 0">{{ row.INaL[2] }}</span>
                  </td>
                  <td v-html="formatBlockRange(row.INa)"></td>
                  <td style="padding-left: 0px" class="has-text-grey">
                    <span v-if="row.INa[2] > 0">{{ row.INa[2] }}</span>
                  </td>
                  <td v-html="formatBlockRange(row.IKs)"></td>
                  <td style="padding-left: 0px" class="has-text-grey">
                    <span v-if="row.IKs[2] > 0">{{ row.IKs[2] }}</span>
                  </td>
                  <td v-html="formatBlockRange(row.Ito)"></td>
                  <td style="padding-left: 0px" class="has-text-grey">
                    <span v-if="row.Ito[2] > 0">{{ row.Ito[2] }}</span>
                  </td>
                  <td v-html="formatBlockRange(row.IK1)"></td>
                  <td style="padding-left: 0px" class="has-text-grey">
                    <span v-if="row.IK1[2] > 0">{{ row.IK1[2] }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <SummaryItem
          label="Therapeutic C<sub>max</sub> (nM)"
          id="s3-cmax"
          v-bind:item="cmax"
          v-if="simType === 2"
        />
        <p class="summary-item" v-if="simType === 2" data-cy="doses">
          <span
            class="has-text-weight-bold"
            v-bind:class="{ 'has-text-warning': !allGood(doses) }"
            >Doses (1):</span
          >
          &nbsp;
          {{ doses.map((d) => d.value).join("; ") }}
        </p>
        <p style="margin-top: 2em" data-cy="tokens">
          The simulation will consume {{ tokenCounter }}
          <span>token</span>
          <span v-if="tokenCounter > 1">s</span>.
          <font-awesome-icon
            title="hERG fitting and Hill fitting each consume one token, as well as every concentration tested in the AP simulation."
            icon="info-circle"
            class="help-icon"
          />
          <span
            class="tag is-medium space-left"
            v-bind:class="{ 'is-warning': tokenCounter > tokensAvailable }"
            >{{ tokensAvailable }} tokens available</span
          >
        </p>
        <p v-if="tokenCounter > tokensAvailable">Not enough available tokens</p>
        <p v-if="!jobValidates">Provide missing input.</p>
        <button
          class="button run-button"
          id="s3-submit"
          v-bind:class="{
            'is-warning': !allowedToSubmit,
            'is-success': allowedToSubmit,
          }"
          v-bind:disabled="isSubmitting || !allowedToSubmit"
          v-on:click="submitJob"
        >
          <Loader v-bind:isLoading="isSubmitting" />Run Simulation
        </button>
        <StepButtons v-model="activeStep" isLast />
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config/apollo-config';
import gql from 'graphql-tag';
import Concentrations from '@/components/Simulation/Concentrations.vue';
import Field from '@/components/Simulation/Field.vue';
import FieldRadio from '@/components/Simulation/FieldRadio.vue';
import Loader from '@/components/Loader.vue';
import StepButtons from '@/components/Simulation/StepButtons.vue';
import StepIndicator from '@/components/Simulation/StepIndicator.vue';
import SummaryItem from '@/components/Simulation/SummaryItem.vue';
import Upload from '@/components/Simulation/Upload.vue';

function allGood(arr) {
  return arr.length > 0 && arr.every((obj) => obj.ok);
}

const allChannels = ['hERG', 'ICaL', 'IK1', 'IKs', 'INa', 'INaL', 'Ito'];

export default {
  components: {
    Concentrations,
    Field,
    FieldRadio,
    Loader,
    StepButtons,
    StepIndicator,
    SummaryItem,
    Upload,
  },
  computed: {
    allowedToSubmit() {
      return this.tokenCounter <= this.tokensAvailable && this.jobValidates;
    },
    hergFilename() {
      if (this.hergFile !== '') {
        return this.hergFile.name;
      }
      return '';
    },
    hillFilename() {
      if (this.hillFile !== '') {
        return this.hillFile.name;
      }
      return '';
    },
  },
  data() {
    return {
      activeStep: 0,
      highestVisitedStep: 0, // up to which step to run validation
      simType: 2,
      simTypeOptions: [
        'hERG fitting',
        'Hill fitting',
        'AP simulation',
      ],
      simTypeLabels: [
        ['Setup', 'hERG Fitting', 'Run'],
        ['Setup', 'Hill Fitting', 'Run'],
        ['Setup', 'hERG Fitting', 'Hill Fitting', 'AP Simulation', 'Run'],
      ],
      hergCsvHeaders: [
        'time',
        'frac',
        'conc',
        'exp',
        'sweep',
      ],
      hillCsvHeaders: [
        'drug',
        'conc',
        'channel',
        'block',
      ],

      // tokens
      tokensAvailable: 0,
      tokenCounter: 0,

      // form status
      isSubmitting: false,
      hergUploadStatus: '',
      hillUploadStatus: '',
      jobValidates: false,
      stepWarnings: [false, false, false, false, false],

      // user data below
      // setup
      drug: { value: '', ok: false, checks: 0 },
      strDrug: 'drug',
      // herg fitting
      hergFile: '',
      hergJson: null,
      hergInfo: [],
      // hill fitting
      hillFile: '',
      hillJson: null,
      hillInfo: [],
      hillChannels: [],
      // AP simulation
      cmax: { value: '', ok: false, checks: 0 },
      strDoses: '',
      doses: [
        { value: '1', ok: true, checks: 0 },
        { value: '10', ok: true, checks: 0 },
      ],
    };
  },
  methods: {
    allGood,
    formatBlockRange(blockRange) {
      if (blockRange[0] === blockRange[1]) return this.$options.filters.nbFormat(blockRange[0]);
      if (blockRange[0] === Infinity) return '';
      const left = this.$options.filters.nbFormat(blockRange[0]);
      const right = this.$options.filters.nbFormat(blockRange[1]);
      return `${left} &ndash; ${right}`;
    },
    async submitJob() {
      this.isSubmitting = true;
      try {
        const inputData = {
          drugname: this.drug.value,
          drug: this.strDrug,
          workflow: null,
          samples: 2000, // can use 60 locally for testing
          seed: 100,
          lambda: 80,
          maxiter: 200, // can use 10 for faster test simulations
          tolerance: 0.001,
          alpha: 0.05,
          burnin: 10000,
          thin: 10,
          channel: 'all',
          cmax: null,
          doses: null,
          hergJson: null,
          hergInfo: null,
          hillJson: null,
          hillInfo: null,
          hillChannels: null,
        };
        if (this.simType === 0) {
          inputData.workflow = 'herg-fitting';
          inputData.hergJson = this.hergJson;
          inputData.hergInfo = this.hergInfo;
        } if (this.simType === 1) {
          inputData.workflow = 'hill-fitting';
          inputData.hillJson = this.hillJson;
          inputData.hillInfo = this.hillInfo;
          inputData.hillChannels = this.hillChannels;
        } else if (this.simType === 2) {
          inputData.workflow = 'ap-simulation';
          inputData.hergJson = this.hergJson;
          inputData.hergInfo = this.hergInfo;
          inputData.hillJson = this.hillJson;
          inputData.hillInfo = this.hillInfo;
          inputData.hillChannels = this.hillChannels;
          inputData.cmax = parseFloat(this.cmax.value);
          inputData.doses = this.doses.map((dose) => parseInt(dose.value, 10));
        }

        if (window.Cypress) {
          delete (inputData.hergJson);
          delete (inputData.hillJson);
          window.testSimulationInputs = inputData; // for retrieval during E2E testing
        }
        const response = await this.$apollo.mutate({
          mutation: gql`
          mutation addCipaJobAndTasks($product_name: ProductName!, $tasks: [CipaTaskIn]!, $job_name: String!) {
            job: addCipaJobAndTasks(product_name: $product_name, tasks: $tasks, job_name: $job_name) {
              _id
            }
          }`,
          variables: {
            product_name: config.productName,
            job_name: this.drug.value,
            tasks: inputData,
          },
        });
        // eslint-disable-next-line no-underscore-dangle
        const jobId = response.data.job._id;
        if (!window.Cypress) {
          window.setTimeout(() => {
            this.$router.push({ name: 'job', params: { id: jobId } });
          }, 1000);
        }
      } catch (error) {
        console.error(error);
      }
      this.isSubmitting = false;
    },
    async fetchTokens() {
      try {
        const response = await this.$apollo.query({
          query: gql`
          query getUserSubscription($product_name: ProductName!) {
            account: getUserSubscription(product_name: $product_name) {
              tokens_available
            }
          }`,
          variables: {
            product_name: config.productName,
          },
        });
        this.tokensAvailable = response.data.account.tokens_available;
      } catch (error) {
        console.error(error);
      }
    },
    handleDosesUpdate(arr) {
      this.doses = arr;
    },
    handleHergFile(hergFile) {
      this.hergFile = hergFile;
      this.hergUploadStatus = 'validating';
    },
    handleHergUploadAccept(inputs) {
      // parse file to display info to user
      const info = {};
      // Using an array for `info` instead of an object would be a bit
      // faster but this also handles a CSV file which is not sorted
      // by concentrations and we're talking < 30 ms either way.
      for (let i = 0; i < inputs.length; i += 1) {
        const row = inputs[i];
        if (!Object.prototype.hasOwnProperty.call(info, row.conc)) {
          info[row.conc] = {
            conc: row.conc,
            experiments: 0,
            sweeps: 0,
            minTime: Infinity,
            maxTime: 0,
            minFrac: Infinity,
            maxFrac: 0,
          };
        }
        const section = info[row.conc];
        if (row.exp > section.experiments) section.experiments = row.exp;
        if (row.sweep > section.sweeps) section.sweeps = row.sweep;
        if (row.time < section.minTime) section.minTime = row.time;
        if (row.time > section.maxTime) section.maxTime = row.time;
        if (row.frac < section.minFrac) section.minFrac = row.frac;
        if (row.frac > section.maxFrac) section.maxFrac = row.frac;
      }
      this.hergJson = inputs;
      this.hergInfo = Object.keys(info).map((conc) => info[conc]);
      this.hergUploadStatus = 'ok';
    },
    handleHergUploadReject() {
      this.hergJson = null;
      this.hergInfo = [];
      this.hergUploadStatus = 'error';
    },
    handleHillFile(hillFile) {
      this.hillFile = hillFile;
      this.hillUploadStatus = 'validating';
    },
    handleHillUploadAccept(indata) {
      // setup of field drug -> "drug"
      const inputs = indata.map((element) => ({ ...element, drug: this.strDrug }));
      const info = {};
      const channels = [];
      for (let i = 0; i < inputs.length; i += 1) {
        const row = inputs[i];
        if (!allChannels.includes(row.channel)) {
          this.$utils.alertError(`Unknown channel '${row.channel}' present in line ${i + 1} in uploaded file.`);
          this.handleHillUploadReject();
          return;
        }
        if (!channels.includes(row.channel)) channels.push(row.channel);
        if (!Object.prototype.hasOwnProperty.call(info, row.conc)) {
          info[row.conc] = {
            conc: row.conc,
            hERG: [Infinity, 0, 0], // starting values for min, max, count
            ICaL: [Infinity, 0, 0],
            INaL: [Infinity, 0, 0],
            INa: [Infinity, 0, 0],
            IKs: [Infinity, 0, 0],
            Ito: [Infinity, 0, 0],
            IK1: [Infinity, 0, 0],
          };
        }
        const channel = info[row.conc][row.channel];
        if (row.block < channel[0]) channel[0] = row.block;
        if (row.block > channel[1]) channel[1] = row.block;
        channel[2] += 1;
      }
      this.hillJson = inputs;
      this.hillInfo = Object.keys(info).map((conc) => info[conc]);
      this.hillChannels = channels;
      this.hillUploadStatus = 'ok';
    },
    handleHillUploadReject() {
      this.hillJson = null;
      this.hillChannels = [];
      this.hillInfo = [];
      this.hillUploadStatus = 'error';
    },
    validateJob() {
      let valid = true;
      const numberSteps = this.simTypeLabels[this.simType].length;
      const stepWarnings = Array(numberSteps).fill(false);
      let tokens = 0;

      // validate title
      if (!this.drug.ok) {
        valid = false;
        stepWarnings[0] = true;
      }

      // validate herg fitting
      if (this.simType === 0 || this.simType === 2) {
        if (this.hergUploadStatus !== 'ok') {
          valid = false;
          stepWarnings[1] = true;
        }
        tokens += 1;
      }

      // validate hill fitting
      if (this.simType === 1 || this.simType === 2) {
        const HillStep = this.simType === 1 ? 1 : 2;
        if (this.hillUploadStatus !== 'ok') {
          valid = false;
          stepWarnings[HillStep] = true; // TODO ?
        }
        tokens += 1;
      }

      // validate AP simulation
      if (this.simType === 2) {
        if (!this.cmax.ok) {
          valid = false;
          stepWarnings[3] = true;
        }
        if (!allGood(this.doses)) {
          valid = false;
          stepWarnings[3] = true;
        }
        tokens += this.doses.length;
      }

      this.jobValidates = valid;
      this.stepWarnings = stepWarnings;
      this.tokenCounter = tokens;
    },
  },
  mounted() {
    this.fetchTokens();
  },
  watch: {
    // eslint-disable-next-line func-names, object-shorthand
    simType: function () {
      this.highestVisitedStep = 0;
      this.hergFile = '';
      this.hergUploadStatus = '';
      this.hergJson = null;
      this.hergInfo = [];
      this.hillFile = '';
      this.hillUploadStatus = '';
      this.hillJson = null;
      this.hillInfo = [];
      this.hillChannels = [];
      this.strDoses = '';
      this.doses = [
        { value: '1', ok: true, checks: 0 },
        { value: '10', ok: true, checks: 0 },
      ];
      this.cmax = { value: '', ok: false, checks: 0 };
      this.tokenCounter = 0;
      const numberSteps = this.simTypeLabels[this.simType].length;
      this.stepWarnings = Array(numberSteps).fill(false);
    },
    // eslint-disable-next-line func-names, object-shorthand
    activeStep: function (newStep) {
      if (newStep > this.highestVisitedStep) this.highestVisitedStep = newStep;
      const numberSteps = this.simTypeLabels[this.simType].length;
      if (newStep === numberSteps - 1) this.validateJob();
    },
  },
};
</script>

<style scoped>
p.readable {
  max-width: 65em;
  margin-bottom: 1em;
}
p.like-label {
  margin-top: 1em;
  margin-bottom: 0.5em;
}
button.run-button {
  margin-top: 1em;
}
table.with-margin {
  margin-top: 0.75em;
  margin-bottom: 1.25em;
}
col.border-right {
  border-right: 1px solid #dbdbdb;
}
.double-tr > th {
  border-style: none;
}
.help-icon {
  cursor: help;
  margin-left: 0.1em;
}
.hill-div {
  margin-bottom: 1em;
}
.space-left {
  margin-left: 0.5em;
}
</style>
