<template>
  <div class="upload">
    <label class="label">
      <span v-if="status === 'ok'" class="icon is-normal has-text-success">
        <font-awesome-icon icon="check" />
      </span>
      <span
        v-else-if="status === 'error'"
        class="icon is-normal has-text-danger"
      >
        <font-awesome-icon icon="times" />
      </span>
      <Loader
        v-else-if="status === 'validating'"
        small
        v-bind:isLoading="true"
      />Upload
      <font-awesome-icon
        icon="info-circle"
        style="cursor: help"
        v-bind:title="help"
      />
    </label>
    <div class="file has-name is-fullwidth">
      <label class="file-label">
        <input
          class="file-input"
          v-bind:name="refName"
          type="file"
          v-bind:ref="refName"
          v-on:change="handleFileUpload()"
        />
        <span class="file-cta">
          <span class="file-icon">
            <font-awesome-icon icon="upload" />
          </span>
          <span class="file-label">Choose a file…</span>
        </span>
        <span class="file-name my-file-name">{{ filename }}</span>
      </label>
    </div>
    <p class="help is-large with-space">
      File must be in CSV format
      <span>(</span>
      <a
        :href="`${publicPath}${exampleFile}`"
        target="_blank"
        rel="noopener noreferrer"
        >download example</a
      >). Hover over help icon for more information.
    </p>
  </div>
</template>

<script>
import Papa from 'papaparse';
import Loader from '@/components/Loader.vue';

Papa.parsePromise = (file) => new Promise((complete, error) => {
  Papa.parse(
    file,
    {
      complete,
      delimiter: ',',
      dynamicTyping: true,
      error,
      header: true,
      transformHeader: (header) => header.toLowerCase(),
      skipEmptyLines: 'greedy',
    },
  );
});

function validateFile(file) {
  const forbiddenFilename = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])$|([<>:"/\\|?*])|(\.|\s)$/ig;
  const allowedFileExtensions = ['csv', 'txt'];

  if (file) {
    if (file.name !== '') {
      if (forbiddenFilename.test(file.name)) {
        throw new Error(`Invalid filename \`${file.name}\` for upload.`);
      }
      const extension = file.name.toLowerCase().split('.').pop();
      if (!allowedFileExtensions.includes(extension)) {
        throw new Error(`File of type \`${extension}\` not accepted. Must be one of ${allowedFileExtensions.join(', ')}.`);
      }
    } else {
      throw new Error('Filename empty.');
    }
  } else {
    throw new Error('File not found.');
  }
}

function validateHeaders(headers, expectedHeaders) {
  for (let i = 0; i < expectedHeaders.length; i += 1) {
    const expected = expectedHeaders[i];
    if (!headers.includes(expected)) {
      throw new Error(`Error parsing uploaded file. Could not find header \`${expectedHeaders[i]}\` in first line of CSV:<br /><pre>${headers}</pre>`);
    }
  }
}

function validateRows(rows, errors) {
  if (rows.length === 0) {
    throw new Error('Expecting at least one row of data.');
  }
  const errorMessages = [];
  for (let i = 0; i < errors.length; i += 1) {
    const e = errors[i];
    const offset = 2; // from line in file to row in data (first line is headers and then +1 for 0-based index)
    if (e.code === 'TooManyFields') {
      errorMessages.push(`Found extra data in line ${e.row + offset}. Did you mean to add concentrations? Use semicolons between test concentrations.`);
    } else {
      errorMessages.push(`${e.message} in line ${e.row + offset}.`);
    }
  }
  if (errorMessages.length > 0) throw new Error(errorMessages.join('<br />'));
}

export default {
  components: {
    Loader,
  },
  computed: {
    icon() {
      return this.status === 'ok' ? 'check' : 'times';
    },
    iconClass() {
      return this.status === 'ok' ? 'has-text-success' : 'has-text-danger';
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  methods: {
    async handleFileUpload() {
      const [file] = this.$refs[this.refName].files;
      this.$emit('upload', file);
      try {
        validateFile(file);
        const parsed = await Papa.parsePromise(file);
        const { fields } = parsed.meta;
        validateHeaders(fields, this.headers);
        const { data, errors } = parsed;
        validateRows(data, errors);
        this.$emit('uploadAccept', data);
      } catch (error) {
        this.$emit('uploadReject');
        this.$utils.alertError(error);
      }
    },
  },
  props: {
    headers: Array,
    help: String,
    exampleFile: String,
    filename: String,
    refName: String,
    status: String,
  },
};
</script>

<style scoped>
div.upload {
  margin-top: 0.5em;
}
</style>
