<template>
  <p class="summary-item" v-bind:id="id">
    <span class="has-text-weight-bold" v-bind:class="{ 'has-text-warning': showWarning }" v-html="label + ':'"></span>
    &nbsp;
    {{ item.value }}
  </p>
</template>

<script>
export default {
  computed: {
    showWarning() {
      return (this.item.ok !== undefined && !this.item.ok);
    },
  },
  props: {
    id: String,
    label: String,
    item: Object, // needs `ok` and `value` attributes
  },
};
</script>

<style scoped>
p.summary-item {
  height: 2em;
}
/*
p.summary-item > span {
  min-width: 11em;
  display: inline-block;
}
*/
</style>
