<template>
  <div>
    <button class="button is-primary is-outlined has-text-dark" v-on:click="show">
      <font-awesome-icon class="icon-with-margin" icon="pencil-alt" />
      Edit
    </button>
    <b-modal :active.sync="isActive" trap-focus aria-role="dialog" has-modal-card aria-modal>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Edit</p>
          <button class="delete" aria-label="close" v-on:click="hide"></button>
        </header>
        <section class="modal-card-body">
          <Field v-model="jobName" label="Title" v-bind:validate="true" />
        </section>
        <footer class="modal-card-foot">
          <button
            class="button is-primary"
            v-bind:disabled="!jobName.ok || isLoading"
            v-on:click="request"
          >
            <Loader v-bind:isLoading="isLoading" />Save changes
          </button>
          <button class="button" v-on:click="hide">Cancel</button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import config from '@/config/apollo-config';
import Field from '@/components/Simulation/Field.vue';
import Loader from '@/components/Loader.vue';

export default {
  components: {
    Field,
    Loader,
  },
  data() {
    return {
      isActive: false,
      isLoading: false,
      jobName: { value: '', ok: false, checks: 0 }, // for editing by user
    };
  },
  methods: {
    show() {
      this.jobName = { value: this.name, ok: true, checks: 0 };
      this.isActive = true;
    },
    hide() {
      this.jobName = { value: this.name, ok: true, checks: 0 };
      this.isActive = false;
    },
    async request() {
      this.isLoading = true;
      try {
        const response = await this.$apollo.mutate({
          mutation: gql`
          mutation editJob($product_name: ProductName!, $job_id: ID!, $job_name: String!) {
            job: editJob(product_name: $product_name, job_id: $job_id, job_name: $job_name) {
              name
            }
          }`,
          variables: {
            product_name: config.productName,
            job_id: this.$route.params.id,
            job_name: this.jobName.value,
          },
        });
        this.$emit('jobNameEdit', response.data.job.name);
        this.hide();
      } catch (error) {
        console.error('error', error);
      }
      this.isLoading = false;
    },
  },
  props: {
    name: String,
  },
};
</script>

<style>
.icon-with-margin {
  margin-right: 0.5em;
}
</style>
