<template>
  <div>
    <section class="section bg-colored-like-navbar">
      <div class="container has-text-centered">
        <h1 class="title is-1 has-text-light">CiPA In Silico</h1>
        <h2 class="subtitle is-3 has-text-light">Comprehensive in vitro Proarrhythmia Assay In Silico</h2>
      </div>
    </section>
    <section class="section bg-colored">
      <div class="container">
        <div class="columns is-vcentered">
          <div class="column">
            <p class="is-size-3">
              A new model that combines dynamic drug-hERG interactions and
              multichannel pharmacology to improve the prediction of the
              torsadogenic risk of drugs
            </p>
          </div>
          <div class="column">
            <figure class="image">
              <img src="../assets/CiPA.jpg" />
            </figure>
          </div>
        </div>
        <div id="Features">
          <div class="columns">
            <div class="column">
              <Feature
                icon="clipboard-check"
                title="Broadly Validated"
                description="Developed by the Division of Applied Regulatory Science, Office of Clinical Pharmacology, Office of Translational Science at FDA"
              />
            </div>
            <div class="column">
              <Feature
                icon="download"
                title="Display and Export"
                description="Results can easily be visualized and downloaded in CSV format"
              />
            </div>
            <div class="column">
              <Feature
                icon="thumbs-up"
                title="Easy to Use"
                description="Well-designed wizard that step by step guides through the setup and run of three different simulation workflows"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Feature from '@/components/Landing/Feature.vue';
import { login } from '@/lib/iam';

export default {
  components: {
    Feature,
  },
  mounted() {
    if (this.$route.matched.some((record) => record.meta.registrationFlow)) {
      login();
    }
  },
};
</script>
<style scoped>
/* Colors */

.bg-colored-like-navbar {
  background-color: #3703ff; /* Deep Blue */
  padding-top: 5em;
  padding-bottom: 3em;
}
.tinted-with-background-color {
  color: hsl(201, 34%, 96%);
}
.bg-colored {
  background-color: #f4f4f4; /* Dust */
}

/* Spacing */

h2.subtitle {
  padding-top: 0.25em;
}
#Features {
  margin-top: 4em;
}
</style>
