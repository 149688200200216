<template>
  <div class="section">
    <div class="container ist-container">
      <div class="field has-addons is-pulled-right">
        <div class="control">
          <input class="input" type="text" placeholder="Enter title" v-model="searchString" />
        </div>
        <div class="control">
          <button class="button is-primary">
            <span class="icon">
              <font-awesome-icon icon="search" />
            </span>
          </button>
        </div>
      </div>
      <h1 class="title">
        <Loader v-bind:isLoading="isLoading" />&nbsp;Activity
      </h1>
      <div class="table-container">
        <table class="ist-table-striped is-fullwidth">
          <thead>
            <tr>
              <th
                v-for="(col, index) in columns"
                v-bind:key="col[0]"
                v-on:click="sortBy(col[1])"
                class="sortable"
                v-bind:class="{left: index < 1}"
              >
                {{ col[0] }}
                <SortedIcon
                  v-if="sortKey == col[1]"
                  v-bind:direction="sortDirection"
                  v-bind:currentSort="sortKey"
                  v-bind:thisSort="col[1]"
                />
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="job in sortedJobs" :key="job._id" v-bind:class="{ 'has-text-grey-light': job.isLoading}">
              <td class="left">{{ job.name }}</td>
              <td>{{ job.tasks[0].created_at | formatDate }}</td>
              <td>{{ job.tasks[0].started_at | formatDate }}</td>
              <td>{{ job.tasks[0].finished_at | formatDate }}</td>
              <td class="has-text-grey">
                <font-awesome-icon v-if="job.tasks[0].status == 'pending'" icon="hourglass-start" />
                <font-awesome-icon v-else-if="job.tasks[0].status === 'running'" icon="hourglass-half" />
                <font-awesome-icon v-else-if="job.tasks[0].status === 'success'" icon="check" />
                <font-awesome-icon v-else-if="job.tasks[0].status === 'warning'" icon="check" class="has-text-warning" />
                <font-awesome-icon v-else icon="times" class="has-text-danger" />
              </td>
              <td class="is-uppercase is-size-7">
                <router-link :to="{name: 'job', params: { id: job._id}}" class="is-primary">View</router-link>
                <span v-if="job.status === 'completed'">
                  &nbsp;&vert;&nbsp;
                  <a class="is-danger" v-on:click="toggleDeleteConfirmationModal(job._id)">Delete</a>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p v-if="sortedJobs.length === 0 && !isLoading">No simulations to show.</p>
      <!-- modal for simulation deletion -->
      <div class="modal" v-bind:class="{'is-active': showingDeleteModal}">
        <div class="modal-background" />
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Confirm</p>
            <button class="delete" aria-label="close" v-on:click="toggleDeleteConfirmationModal" />
          </header>
          <section class="modal-card-body">Are you sure you want to delete this simulation?</section>
          <footer class="modal-card-foot">
            <button class="button is-danger" v-on:click="deleteSimulation">Delete</button>
            <button class="button" v-on:click="toggleDeleteConfirmationModal">Cancel</button>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from '@/components/Loader.vue';
import SortedIcon from '@/components/SortedIcon.vue';
import gql from 'graphql-tag';
import config from '@/config/apollo-config';

export default {
  components: {
    Loader,
    SortedIcon,
  },
  data() {
    return {
      isLoading: false,
      jobs: [],
      columns: [ // of activity table
        ['Title', 'name'],
        ['Created', 'created_at'],
        ['Started', 'started_at'],
        ['Completed', 'finished_at'],
        ['Status', 'status'],
      ],
      sortKey: 'created_at',
      sortDirection: 'asc',
      // search/filter
      filteredJobs: [],
      searchString: '',
      awaitingSearch: false, // for debounce
      // job deletion
      showingDeleteModal: false,
      deleteModalSimulationId: null,
    };
  },
  methods: {
    async fetch() {
      this.isLoading = true;
      try {
        const response = await this.$apollo.query({
          query: gql`
            query getJobs($product_name: ProductName!) {
              jobs: getJobs(product_name: $product_name) {
                _id
                name
                status
                tasks {
                  status
                  created_at
                  started_at
                  finished_at
                }
              }
            }
          `,
          variables: {
            product_name: config.productName,
          },
        });
        this.jobs = response.data.jobs;
        this.sortBy('created_at');
      } catch (error) {
        this.$utils.alertError(error);
        throw error;
      }
      this.isLoading = false;
    },
    filterJobs() {
      const search = this.searchString.toLowerCase();
      if (search === '') {
        this.filteredJobs = this.jobs;
        return;
      }
      this.filteredJobs = this.jobs.filter((job) => job.name.toLowerCase().indexOf(search) > -1);
    },
    sortBy(sortBy) {
      // if s == current sort, reverse
      if (sortBy === this.sortKey) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      }
      this.sortKey = sortBy;
    },
    toggleDeleteConfirmationModal(simulationId = null) {
      if (simulationId != null) {
        this.deleteModalSimulationId = simulationId;
      }
      this.showingDeleteModal = !this.showingDeleteModal;
    },
    async deleteSimulation() {
      const databaseId = this.deleteModalSimulationId;
      this.toggleDeleteConfirmationModal(null);
      let deleteJobArrayIndex = null;
      const remainingJobs = this.jobs.filter((job, index) => {
        // eslint-disable-next-line no-underscore-dangle
        if (job._id === databaseId) {
          deleteJobArrayIndex = index;
          return false;
        }
        return true;
      });
      try {
        this.jobs[deleteJobArrayIndex].isLoading = true; // for presentation purposes
        await this.$apollo.mutate({
          mutation: gql`
          mutation deleteJob($product_name: ProductName!, $job_id: ID!) {
            job: deleteJob(product_name: $product_name, job_id: $job_id)
          }`,
          variables: {
            product_name: config.productName,
            job_id: databaseId,
          },
        });
      } catch (error) {
        this.jobs[deleteJobArrayIndex].isLoading = false;
        console.error(error);
      }
      this.jobs = remainingJobs;
      this.filterJobs();
    },
  },
  async mounted() {
    await this.fetch();
    this.filterJobs();
  },
  watch: {
    searchString() {
      const debounceMilliSeconds = 300;
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.filterJobs();
          this.awaitingSearch = false;
        }, debounceMilliSeconds);
      }
      this.awaitingSearch = true;
    },
  },
  computed: {
    sortedJobs() {
      const modifier = this.sortDirection === 'asc' ? 1 : -1;
      if (['created_at', 'started_at', 'finished_at', 'status'].includes(this.sortKey)) {
        return this.filteredJobs.slice().sort((a, b) => {
          if (a.tasks[0][this.sortKey] < b.tasks[0][this.sortKey]) return -modifier;
          if (a.tasks[0][this.sortKey] > b.tasks[0][this.sortKey]) return modifier;
          return 0;
        });
      }
      if (this.sortKey === 'name') {
        return this.filteredJobs.slice().sort((a, b) => {
          if (a[this.sortKey].toLowerCase() < b[this.sortKey].toLowerCase()) return -modifier;
          if (a[this.sortKey].toLowerCase() > b[this.sortKey].toLowerCase()) return modifier;
          return 0;
        });
      }
      return this.filteredJobs.slice().sort((a, b) => {
        if (a[this.sortKey] < b[this.sortKey]) return -modifier;
        if (a[this.sortKey] > b[this.sortKey]) return modifier;
        return 0;
      });
    },
  },
};
</script>
<style scoped>
th.sortable {
  cursor: pointer;
}
</style>
