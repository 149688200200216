<template>
  <div class="howtouse">
    <h1 class="title">How to Use</h1>
    <p class="has-text-justified with-space">
      CiPA In Silico can be used to set up three different simulation workflows:
      hERG fitting, Hill fitting, and AP simulation.
    </p>
    <p class="has-text-justified with-space">
      <strong>hERG fitting</strong> performs uncertainty characterization for
      drug binding kinetics of the human Ether-à-go-go-Related Gene (hERG)
      channel gating model. It requires the upload of a CSV file containing
      laboratory data on time of recording, fractional current recorded, drug
      concentration, cell, and sweep number.
    </p>
    <p class="has-text-justified with-space">
      <strong>Hill fitting</strong> performs uncertainty characterization of
      dose-response curves for up to six ionic currents
      <span>(</span>
      <span>I</span>
      <sub>CaL</sub>,
      <span>I</span>
      <sub>NaL</sub>,
      <span>I</span>
      <sub>Na</sub>,
      <span>I</span>
      <sub>to</sub>,
      <span>I</span>
      <sub>Ks</sub>, and
      <span>I</span>
      <sub>K1</sub>
      <span>)</span>.
      It requires the upload of a CSV file containing pharmacology data on drug
      concentration, ion channel type and percentage of ion channel block.
    </p>
    <p class="has-text-justified with-space">
      <strong>AP simulation</strong> combines the results of the modules hERG
      fitting and Hill fitting to simulate for a compound at a given
      concentration its effects on the Action Potential (AP) of a population of
      ventricular cardiomyocyte cells and to estimate from them the population
      value of the safety marker qNet and its uncertainty interval. The module
      requires the upload of CSV files for hERG and Hill fitting as described
      before. In addition, it requires the expected maximal therapeutic
      concentration
      <span>(C</span>
      <sub>max</sub>) of the compound of interest and a few
      <span>C</span>
      <sub>max</sub> multipliers in the range 1 &ndash; 30.
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>
<style scoped>
div.howtouse {
  padding-right: 2em;
  line-height: 1.7;
}
</style>
