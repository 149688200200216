<template>
  <div>
    <div class="field">
      <label class="label">
        <span>
          Multipliers
          <span>C</span>
          <sub>max</sub>
        </span>
        <font-awesome-icon
          icon="info-circle"
          title="List of integer multipliers of Cmax in the interval 2-30 separated by semicolons, e.g. 3;5;20."
          class="help-icon"
        />
      </label>
      <p class="control">
        <input
          type="text"
          class="input"
          id="s3-concentrations"
          v-bind:value="value"
          v-on:input="onInput"
          v-bind:class="{ 'is-warning': hasWarning }"
        />
      </p>
      <p class="help">
        <span class="has-text-warning" v-if="hasWarning">
          <font-awesome-icon icon="exclamation-triangle" class="warning-icon" />
          &nbsp;{{ errorMessage }}
        </span>
      </p>
    </div>
    <p>Multipliers check:</p>
    <ul class="with-space">
      <li class="with-space" v-if="concentrations.length === 0">1</li>
      <li class="with-space" v-for="(v, index) in concentrations" :key="index" v-bind:class="{ 'has-text-warning': !v.ok && v.checks>0 }">
        {{v.value}}
        <span class="has-text-warning" v-if="!v.ok && v.checks>0">
          <font-awesome-icon icon="exclamation-triangle" />
        </span>
      </li>
      <li class="with-space" v-if="concentrations.length === 0">10</li>
    </ul>
  </div>
</template>

<script>

function allGood(arr) {
  return arr.length > 0 && arr.every((obj) => obj.ok);
}

export default {
  computed: {
    hasWarning() {
      return this.validate && !allGood(this.concentrations);
    },
    errorMessage() {
      if (this.value === '') return 'Missing input.';
      return 'Invalid input.';
    },
  },
  methods: {
    allGood,
    onInput(event) { // no matter what user enters, values 1 and 10 included exactly once
      const { value } = event.target;
      const concentrations = [{ value: '1', ok: true, checks: 0 }]; // pre-fill with 1
      value.split(';').forEach((v) => {
        if (v === '') return; // ignore the occurence of double semicolons `;;`
        const ok = !Number.isNaN(v) && Number.isInteger(Number(v)) && v >= 1 && v <= 30;
        const oneOrTen = Number(v) === 1 || Number(v) === 10;
        if (!oneOrTen) { // skip value if its 1 or 10
          concentrations.push({
            value: v, ok, checks: 1,
          });
        }
      });
      concentrations.push({ value: '10', ok: true, checks: 0 }); // top off with 10
      concentrations.sort((a, b) => (Number(a.value) - Number(b.value)));
      this.$emit('input', value);
      this.$emit('concentrationsUpdate', concentrations);
    },
  },
  props: {
    value: String,
    concentrations: Array,
    validate: Boolean,
  },
};
</script>

<style scoped>
.help-icon {
  cursor: help;
  margin-left: 0.25em;
}
</style>
