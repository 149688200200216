<template>
  <span v-bind:class="{ 'my-loader': isLoading, 'small': small }" />
</template>

<script>
export default {
  props: {
    isLoading: Boolean,
    small: Boolean,
  },
};
</script>

<style scoped>
.my-loader {
  border: 7px solid #f4f4f4;
  border-top: 7px solid #6ccffa;
  border-radius: 50%;
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;
  animation: spin 1s linear infinite;
  margin-right: 0.5em;
}
.small {
  height: 1em;
  width: 1em;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
