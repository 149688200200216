<template>
  <div>
    <div class="field has-addons is-pulled-right">
      <div class="control">
        <input class="input" type="text" placeholder="Enter title" v-model="searchString" />
      </div>
      <div class="control">
        <button class="button is-primary">
          <span class="icon">
            <font-awesome-icon icon="search" />
          </span>
        </button>
      </div>
    </div>
    <h1 class="title latest-activity">
      <Loader v-bind:isLoading="isLoading" />Latest Activity
    </h1>
    <div class="table-container">
      <table class="ist-table-striped is-fullwidth is-hoverable">
        <thead>
          <tr>
            <th class="left">Title</th>
            <th>Started</th>
            <th>Finished</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="job in filteredJobs" v-bind:key="job._id" class="clickable" v-on:click="clickAction(job._id)">
            <td class="left">{{ job.name }}</td>
            <td>{{ job.tasks[0].started_at | formatDate }}</td>
            <td>{{ job.tasks[0].finished_at | formatDate }}</td>
            <td class="has-text-grey">
              <font-awesome-icon v-if="job.tasks[0].status == 'pending'" icon="hourglass-start" />
              <font-awesome-icon v-else-if="job.tasks[0].status === 'running'" icon="hourglass-half" />
              <font-awesome-icon v-else-if="job.tasks[0].status === 'success'" icon="check" />
              <font-awesome-icon v-else-if="job.tasks[0].status === 'warning'" icon="check" class="has-text-warning" />
              <font-awesome-icon v-else icon="times" class="has-text-danger" />
            </td>
            <td class="is-uppercase is-size-7">
              <router-link :to="{name: 'job', params: { id: job._id}}" class="is-primary">View</router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p class="with-margin" v-if="filteredJobs.length === 0 && !isLoading">No simulations to show.</p>
    <router-link v-bind:to="{name: 'activity'}" class="button is-primary with-space">See All</router-link>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import config from '@/config/apollo-config';
import Loader from '@/components/Loader.vue';

export default {
  components: {
    Loader,
  },
  data() {
    return {
      isLoading: false,
      jobs: [],
      searchString: '',
      awaitingSearch: false,
      filteredJobs: [],
    };
  },
  methods: {
    async fetch() {
      this.isLoading = true;
      try {
        const response = await this.$apollo.query({
          query: gql`
            query getJobs($product_name: ProductName!, $limit: Int) {
              jobs: getJobs(product_name: $product_name, limit: $limit) {
                _id,
                name,
                status
                tasks {
                  status
                  started_at
                  finished_at
                }
              }
            }
          `,
          variables: {
            product_name: config.productName,
          },
        });
        this.jobs = response.data.jobs;
      } catch (error) {
        console.error('error', error);
      }
      this.isLoading = false;
    },
    clickAction(jobId) {
      this.$router.push({ name: 'job', params: { id: jobId } });
    },
    filterJobs() {
      if (this.searchString === '') {
        this.filteredJobs = this.jobs.slice(0, 5);
      } else {
        const search = this.searchString.toLowerCase();
        this.filteredJobs = this.jobs.filter((job) => job.name.toLowerCase().indexOf(search) > -1);
      }
    },
  },
  async mounted() {
    await this.fetch();
    this.filterJobs();
  },
  watch: {
    searchString() {
      const debounceMilliSeconds = 300;
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.filterJobs();
          this.awaitingSearch = false;
        }, debounceMilliSeconds);
      }
      this.awaitingSearch = true;
    },
  },
};
</script>
<style scoped>
h1.latest-activity {
  margin-bottom: 1em;
}
p.with-margin {
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}
ul {
  list-style: disc outside;
  padding-left: 1.25em;
}
ul > li {
  margin-bottom: 0.25em;
}
.clickable {
  cursor: pointer;
}
</style>
