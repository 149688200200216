<template>
  <div class="space-bottom">
    <template v-if="!isShowing">
      <a v-on:click="toggle" href="#" class="is-uppercase is-size-7" data-cy="show-inputs">&nbsp;Show input...</a>
    </template>
    <template v-else>
      <p>
        <a v-on:click="toggle" class="is-uppercase is-size-7">Hide input</a>
      </p>
      <div v-if="input.workflow == 'ap-simulation'">
        <p class="definition" data-cy="inputs-ap-cmax">
          <span class="has-text-weight-bold">
            Therapeutic
            <span>C</span>
            <sub>max</sub>:
          </span>
          {{ input.cmax }} nM
        </p>
        <p class="definition" data-cy="inputs-ap-doses">
          <span class="has-text-weight-bold">Doses:</span>
          {{ input.doses.split(',').join(', ') }} x
          <span>C</span>
          <sub>max</sub>
        </p>
      </div>
      <div class="table-container with-space" v-if="input.hergInfo != null && input.hergInfo.length > 0">
        <table class="ist-table with-margin" data-cy="inputs-herg-table">
          <thead>
            <tr>
              <th>Conc (nM)</th>
              <th># Experiments</th>
              <th>
                # Sweeps
                <sup>
                  <font-awesome-icon title="Number of sweeps per experiment." icon="info-circle" class="help-icon" />
                </sup>
              </th>
              <th>Time (ms)</th>
              <th>Frac Current</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in input.hergInfo" v-bind:key="index">
              <td>{{ row.conc | nbFormat }}</td>
              <td>{{ row.experiments }}</td>
              <td>{{ row.sweeps }}</td>
              <td>{{ row.minTime }} &ndash; {{ row.maxTime }}</td>
              <td>{{ row.minFrac | nbFormat }} &ndash; {{ row.maxFrac | nbFormat }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-container with-space" v-if="input.hillInfo != null && input.hillInfo.length > 0">
        <table class="ist-table with-margin" data-cy="inputs-hill-table">
          <colgroup>
            <col class="border-right" />
            <col span="7" />
          </colgroup>
          <thead>
            <tr class="double-tr">
              <th colspan="1"></th>
              <th colspan="14" class="has-text-centered">Channel Block %</th>
            </tr>
            <tr>
              <th>Conc (nM)</th>
              <th>
                <span>I</span>
                <sub>hERG</sub>
              </th>
              <th style="padding-left: 0px;">#</th>
              <th>
                <span>I</span>
                <sub>CaL</sub>
              </th>
              <th style="padding-left: 0px;">#</th>
              <th>
                <span>I</span>
                <sub>NaL</sub>
              </th>
              <th style="padding-left: 0px;">#</th>
              <th>
                <span>I</span>
                <sub>Na</sub>
              </th>
              <th style="padding-left: 0px;">#</th>
              <th>
                <span>I</span>
                <sub>Ks</sub>
              </th>
              <th style="padding-left: 0px;">#</th>
              <th>
                <span>I</span>
                <sub>to</sub>
              </th>
              <th style="padding-left: 0px;">#</th>
              <th>
                <span>I</span>
                <sub>K1</sub>
              </th>
              <th style="padding-left: 0px;">#</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in input.hillInfo" v-bind:key="index">
              <td>{{ row.conc }}</td>
              <td v-html="formatBlockRange(row.hERG)"></td>
              <td style="padding-left: 0px;" class="has-text-grey">
                <span v-if="row.hERG[2] > 0">{{ row.hERG[2] }}</span>
              </td>
              <td v-html="formatBlockRange(row.ICaL)"></td>
              <td style="padding-left: 0px;" class="has-text-grey">
                <span v-if="row.ICaL[2] > 0">{{ row.ICaL[2] }}</span>
              </td>
              <td v-html="formatBlockRange(row.INaL)"></td>
              <td style="padding-left: 0px;" class="has-text-grey">
                <span v-if="row.INaL[2] > 0">{{ row.INaL[2] }}</span>
              </td>
              <td v-html="formatBlockRange(row.INa)"></td>
              <td style="padding-left: 0px;" class="has-text-grey">
                <span v-if="row.INa[2] > 0">{{ row.INa[2] }}</span>
              </td>
              <td v-html="formatBlockRange(row.IKs)"></td>
              <td style="padding-left: 0px;" class="has-text-grey">
                <span v-if="row.IKs[2] > 0">{{ row.IKs[2] }}</span>
              </td>
              <td v-html="formatBlockRange(row.Ito)"></td>
              <td style="padding-left: 0px;" class="has-text-grey">
                <span v-if="row.Ito[2] > 0">{{ row.Ito[2] }}</span>
              </td>
              <td v-html="formatBlockRange(row.IK1)"></td>
              <td style="padding-left: 0px;" class="has-text-grey">
                <span v-if="row.IK1[2] > 0">{{ row.IK1[2] }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  computed: {
    concentrations() {
      return '';
    },
  },
  data() {
    return {
      isShowing: false,
      channels: ['NaF', 'Kr', 'CaL', 'NaL', 'Ks', 'to', 'K1'],
    };
  },
  methods: {
    toggle() {
      this.isShowing = !this.isShowing;
    },
    formatBlockRange(blockRange) {
      if (blockRange[0] === blockRange[1]) return this.$options.filters.nbFormat(blockRange[0]);
      if (blockRange[0] === Infinity || blockRange[0] === null) return '';
      const left = this.$options.filters.nbFormat(blockRange[0]);
      const right = this.$options.filters.nbFormat(blockRange[1]);
      return `${left} &ndash; ${right}`;
    },
  },
  props: {
    input: Object,
  },
};
</script>

<style scoped>
col.border-right {
  border-right: 1px solid #dbdbdb;
}
.no-border > th {
  border-style: none !important;
}
div.space-bottom {
  margin-bottom: 1em;
}
.double-tr > th {
  border-style: none;
}
p.definition {
  line-height: 2;
}
</style>
