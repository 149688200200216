<template>
  <div>
    <p class="welcome">
      <Loader v-bind:isLoading="isLoading" />
      Welcome, {{userName}}!
    </p>
    <h1 class="title">New Simulation</h1>
    <p class="tokens">
      Available tokens:
      <span class="tag is-medium is-light">{{tokensAvailable}} tokens</span>
    </p>
    <router-link :to="{name: 'simulation'}" class="button is-primary">Create</router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import gql from 'graphql-tag';
import config from '@/config/apollo-config';
import Loader from '@/components/Loader.vue';

export default {
  components: {
    Loader,
  },
  computed: mapState({
    userName: (state) => {
      if (state.iam.user) {
        return state.iam.user.name;
      }
      return 'user';
    },
  }),
  data() {
    return {
      isLoading: false,
      tokensAvailable: 0,
    };
  },
  methods: {
    async fetch() {
      this.isLoading = true;
      try {
        const response = await this.$apollo.query({
          query: gql`
          query getUserSubscription($product_name: ProductName!) {
            account: getUserSubscription(product_name: $product_name) {
              product_name
              total_tokens
              tokens_used
              tokens_available
              status
              expiration_datetime
            }
          }`,
          variables: {
            product_name: config.productName,
          },
        });
        const { account } = response.data;
        if (account != null) {
          this.tokensAvailable = account.tokens_available;
        }
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>

<style scoped>
p.welcome {
  margin-bottom: 0.5em;
}
p.tokens {
  margin-bottom: 1.5em;
}
</style>
